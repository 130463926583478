import * as React from 'react';
import Banner from '../components/HomeComponets/Banner';
import ScrollSection from '../components/HomeComponets/ScrollSection';
import FAQs from '../components/HomeComponets/FAQ';
import Contact from '../components/HomeComponets/Contact';
import Footer from '../components/HomeComponets/Footer';
import CookiePopup from '../components/UI/CookiePopup/CookiePopup';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const IndexPage = () => {
	const [showCookie, setShowCookie] = useState(false);
	useEffect(() => {
		setTimeout(() => {
			setShowCookie(true);
		}, 5000);
	}, []);
	return (
		<>
			<Helmet>
				<title>Starch® - Guilt free compostable packaging</title>
				<meta
					name="title"
					content="Starch® - Guilt free compostable packaging"
				/>
				<meta
					name="description"
					content="Starch manufactures fully compostable flexible packaging solutions that help businesses source sustainable packaging"
				/>

				<meta property="og:type" content="website" />
				<meta property="og:url" content="https://joinstarch.com/" />
				<meta
					property="og:title"
					content="Starch® - Guilt free compostable packaging"
				/>
				<meta
					property="og:description"
					content="Starch manufactures fully compostable flexible packaging solutions that help businesses source sustainable packaging"
				/>
				<meta
					property="og:image"
					content="https://cdn.discordapp.com/attachments/907064417731112961/907489774661685268/Instagram_Post_82x.PNG"
				/>

				<meta property="twitter:card" content="summary_large_image" />
				<meta property="twitter:url" content="https://joinstarch.com/" />
				<meta
					property="twitter:title"
					content="Starch® - Guilt free compostable packaging"
				/>
				<meta
					property="twitter:description"
					content="Starch manufactures fully compostable flexible packaging solutions that help businesses source sustainable packaging"
				/>
				<meta
					property="twitter:image"
					content="https://cdn.discordapp.com/attachments/907064417731112961/907489774661685268/Instagram_Post_82x.PNG"
				/>
			</Helmet>
			<Banner />
			<FAQs />
			<Contact />
			<Footer />
			<CookiePopup showCookie={showCookie} setShowCookie={setShowCookie} />
		</>
	);
};

export default IndexPage;
