import React from 'react';
import '../../styles/HomeComponent/Footer.scss';
import compostable from '/src/images/compostable.png';
import okCompost from '/src/images/okCompost.png';

const Footer = () => {
	return (
		<section className="section-footer">
			<div className="container">
				<h2>
					<a href="mailto:info@joinstarch.com">info@joinstarch.com</a>
				</h2>
				<div>
					<img src={compostable} alt="compostable" />
					<img src={okCompost} alt="ok Compost" />
				</div>
				<div className="foot">
					<ul>
						<li>
							<a
								href="https://www.instagram.com/joinstarch/"
								rel="noreferrer"
								target={'_blank'}
							>
								Instagram
							</a>
						</li>
						<li>
							<a
								href="https://www.linkedin.com/company/joinstarch"
								rel="noreferrer"
								target={'_blank'}
							>
								LinkedIn
							</a>
						</li>
					</ul>
				</div>
			</div>
		</section>
	);
};

export default Footer;
