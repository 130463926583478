import React, { useState } from 'react';
import '../../styles/HomeComponent/Contact.scss';
import RightIcon from './../../assets/arrow-right.svg';
import AboutSelf from './ContactForms/AboutSelf';
import PackagingReq from './ContactForms/PackagingReq';
import ContactInfo from './ContactForms/ContactInfo';
import Confirmation from './ContactForms/Confermation';
import { AnimatePresence, motion } from 'framer-motion';
import axios from 'axios';

const Contact = () => {
	const defaultFromData = {
		occupation: '',
		companyName: '',
		basedIn: '',
		lookingFor: '',
		segment: '',
		pack: '',
		quantity: '',
		name: '',
		email: '',
		message: '',
	};
	const [steps, setSteps] = useState(1);
	const [formData, setFormData] = useState(defaultFromData);
	const [showError, setShowError] = useState({ show: false, message: '' });
	const openNewForm = () => {
		setSteps(1);
		setFormData(defaultFromData);
	};
	const handleError = (emailError = '') => {
		if (emailError !== '') {
			setShowError({ show: true, message: emailError });
		} else {
			setShowError({ show: true, message: '' });
		}
		setTimeout(() => {
			setShowError({ show: false, message: '' });
		}, 3000);
	};
	const validateForm = (step) => {
		let re =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (step === 1) {
			if (
				formData.occupation === '' ||
				formData.companyName === '' ||
				formData.basedIn === ''
			) {
				handleError();
			} else {
				setSteps(steps + 1);
			}
		} else if (step === 2) {
			if (
				formData.lookingFor === '' ||
				formData.segment === '' ||
				formData.quantity === ''
			) {
				handleError();
			} else {
				setSteps(steps + 1);
			}
		} else if (step === 3) {
			if (formData.name === '' || formData.email === '') {
				handleError();
			} else if (!re.test(formData.email)) {
				handleError('Not a valid email');
			} else {
				setSteps(steps + 1);

				axios({
					method: 'post',
					url: 'https://joinstarch-api.herokuapp.com/api/makerequest',
					data: formData,
				})
					.then(() => {
						console.log('success');
					})
					.catch(() => {
						console.log('failed');
					});
			}
		}
	};

	const variant = {
		show: { opacity: 1, x: 0, delay: 0.5 },
		hidden: { opacity: 0, x: '-100px' },
	};
	return (
		<section id="contact" className={`contact ${steps === 4 && 'fullScreen'}`}>
			<div className="container">
				<div className="body">
					<AnimatePresence exitBeforeEnter>
						{steps === 1 && (
							<motion.div
								variants={variant}
								initial={null}
								exit="hidden"
								key={'about'}
							>
								<AboutSelf
									showError={showError}
									formData={formData}
									setFormData={setFormData}
								/>
							</motion.div>
						)}
						{steps === 2 && (
							<motion.div
								variants={variant}
								initial={null}
								exit="hidden"
								key={'packaging'}
							>
								<PackagingReq
									showError={showError}
									formData={formData}
									setFormData={setFormData}
								/>
							</motion.div>
						)}

						{steps === 3 && (
							<motion.div
								variants={variant}
								initial={null}
								exit="hidden"
								key={'contact'}
							>
								<ContactInfo
									showError={showError}
									formData={formData}
									setFormData={setFormData}
								/>
							</motion.div>
						)}
						{steps === 4 && (
							<motion.div
								variants={variant}
								initial={'hidden'}
								animate={'show'}
								exit="hidden"
								key={'conformation'}
							>
								<Confirmation all={formData} name={formData.name} />
							</motion.div>
						)}
					</AnimatePresence>
					<AnimatePresence exitBeforeEnter>
						{showError.show && (
							<motion.p
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								className="error"
							>
								{showError.message === ''
									? `Please fill all the required fields`
									: showError.message}
							</motion.p>
						)}
					</AnimatePresence>
				</div>
				<div className="foot">
					{steps < 4 && (
						<div className="steps">
							<span>0{steps}/</span>
							<span>03</span>
						</div>
					)}
					<div className="buttons">
						{steps !== 1 && steps !== 4 ? (
							<button onClick={() => setSteps(steps - 1)} className={'prev'}>
								<RightIcon />
							</button>
						) : null}
						{steps < 3 && (
							<button onClick={() => validateForm(steps)} className={'next'}>
								<RightIcon />
							</button>
						)}
						{steps === 3 && (
							<button
								onClick={() => validateForm(steps)}
								className={'next submit'}
							>
								Send
							</button>
						)}
						{steps === 4 && (
							<button onClick={openNewForm} className={'next' + ' newFrom'}>
								Home
							</button>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contact;
