import React, {useState} from 'react';
import PropTypes from "prop-types";
import {AnimatePresence, motion} from "framer-motion";

const ContactInfo = ({formData, setFormData,showError}) => {
    const [name, setName] = useState(formData.name)
    const [email, setEmail] = useState(formData.email)
    const [message, setMessage] = useState(formData.message)

    const setInput = (key, value) => {
        let newData = formData;
        if (key === 'name') {
            setName(value);
            newData.name = value;
        } else if (key === 'email') {
            setEmail(value);
            newData.email = value;
        } else if (key === 'message') {
            setMessage(value);
            newData.message = value;
        }
        setFormData(newData)
    }
    return (
        <div className={'flex'}>
            <h3>
                Your contact information
            </h3>
            <div className={'side'}>
                <div className="input-group">
                    <div className={'input-placeholder'}>
                        <input type="text"
                               className={'input'}
                               required
                               value={name}
                               onChange={(e) => setInput('name', e.target.value)}
                        />
                        <div className="placeholder">
                            Name<span>*</span>
                        </div>
                        <AnimatePresence exitBeforeEnter>
                            {(showError.show && name === '') && (
                                <motion.p
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    exit={{opacity: 0}}
                                    className="error small">
                                    {'Missing field'}</motion.p>)}
                        </AnimatePresence>
                    </div>

                    <div className={'input-placeholder'}>
                        <input type="text"
                               className={'input'}
                               required
                               value={email}
                               onChange={(e) => setInput('email', e.target.value)}
                        />
                        <div className="placeholder">
                            Email<span>*</span>
                        </div>
                        <AnimatePresence exitBeforeEnter>
                            {(showError.show && email === '') && (
                                <motion.p
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    exit={{opacity: 0}}
                                    className="error small">
                                    {'Missing field'}</motion.p>)}
                        </AnimatePresence>
                    </div>
                    <input type="email"
                           className={'input'}
                           value={message}
                           onChange={(e) => setInput('message', e.target.value)}
                           placeholder={'Message'}/>
                </div>
            </div>
        </div>
    );
};
ContactInfo.propTypes = {
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired,
    showError: PropTypes.bool.isRequired
}
export default ContactInfo;
