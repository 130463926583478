import React from 'react';
import './../../styles/HomeComponent/Banner.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
	Pagination,
	Autoplay,
	Mousewheel,
	EffectFade,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';

import img1 from '/src/images/slide01.webp';
import img2 from '/src/images/slide02.webp';
import img3 from '/src/images/slide03.webp';
import img4 from '/src/images/slide04.webp';
import img5 from '/src/images/slide05.webp';
import TweenMax, { gsap, Linear } from 'gsap/gsap-core';

SwiperCore.use([Autoplay, Pagination, EffectFade, Mousewheel]);

const Banner = () => {
	const slides = [
		{
			title: ['longer shelf life   ', 'hello plant made   '],
			textTop: 'starch®',
			textBottom: 'plant-based packaging',
			backgroundColor: '#81CDF7',
			image: img2,
		},
		{
			title: ['support a green   ', 'retail shopping   '],
			textTop: 'starch®',
			textBottom: 'plant-based packaging',
			backgroundColor: '#43B05B',
			image: img1,
		},
		{
			title: ['100% sustainable   '],
			textTop: 'starch®',
			textBottom: 'plant-based packaging',
			backgroundColor: '#F9B14E',
			image: img3,
		},
		{
			title: [
				'fresh fruits containers     ',
				'made for a circular' + ' economy    ',
			],
			textTop: 'starch®',
			textBottom: 'plant-based packaging',
			backgroundColor: '#1B8599',
			image: img4,
		},
		{
			title: ['take your brand  ', ' brand compostable    '],
			textTop: 'starch®',
			textBottom: 'plant-based packaging',
			backgroundColor: '#E0C76C',
			image: img5,
		},
	];

	function animateText() {
		let marquee = document.querySelectorAll('.swiper-slide .inner');
		marquee.forEach((el) => {
			// set a default rate, the higher the value, the faster it is
			let rate = 100;
			if (window.innerWidth < 500) {
				rate = 100;
			}
			// get the width of the element
			let distance = el.clientWidth;
			// get the margin-right of the element
			let style = window.getComputedStyle(el);
			let marginRight = parseInt(style.marginRight) || 0;
			// get the total width of the element
			let totalDistance = distance + marginRight;
			// get the duration of the animation
			// for a better explanation, see the quoted codepen in the first comment
			let time = totalDistance / rate;
			// get the parent of the element
			let container = el.parentElement;
			let direction = container.dataset.direction;
			gsap.set(container, { x: 0 });
			if (direction % 2 === 0) {
				TweenMax.to(container, {
					ease: Linear.easeNone,
					x: '-' + totalDistance,
					repeat: -1,
					yoyo: true,
					duration: time,
				});
			} else {
				TweenMax.to(container, {
					ease: Linear.easeNone,
					x: '+' + totalDistance,

					repeat: -1,
					yoyo: true,
					duration: time,
				});
			}
		});
	}

	const animateImage = (slide) => {
		let image = document.querySelector('.swiper-slide-active img');
		gsap.set(image, { y: 50 });
		gsap.to(image, { y: 0 });
	};
	const swiperParams = {
		direction: 'vertical',
		speed: 900,
		effect: 'fade',
		pagination: {
			clickable: true,
		},
		mousewheel: true,
		slidesPerView: 1,
	};

	return (
		<section className={'banner'}>
			<Swiper
				{...swiperParams}
				onSlideNextTransitionStart={(slide) => animateImage(slide)}
				onSlidePrevTransitionStart={(slide) => animateImage(slide)}
				onInit={(slide) => animateText(slide)}
				onSlideChange={(swiper) => {
					setTimeout(function () {
						swiper.params.mousewheel.releaseOnEdges = false;
					}, 500);
				}}
				onReachEnd={(swiper) => {
					setTimeout(function () {
						swiper.params.mousewheel.releaseOnEdges = true;
					}, 750);
				}}
			>
				{slides.map((item, index) => (
					<SwiperSlide
						key={item.title}
						className={`slide${index + 1}`}
						style={{ backgroundColor: item.backgroundColor }}
					>
						<div className="container">
							<span>{item.textTop}</span>
							<span className={'text-bottom'}>{item.textBottom}</span>
							<div className="img-wrapper">
								<img src={item.image} alt={item.title} />
							</div>
							<h2>
								{item.title.map((item, index) => (
									<div
										className="wrapper"
										key={index}
										data-direction={index + 1}
									>
										<span className={'inner'}>{item}</span>
										<span className={'inner'}>{item}</span>
										<span className={'inner'}>{item}</span>
										<span className={'inner'}>{item}</span>
									</div>
								))}
							</h2>
						</div>
					</SwiperSlide>
				))}
				<a href={'#faqs'} className="scrollDown">
					Scroll Down
				</a>
			</Swiper>
		</section>
	);
};

export default Banner;
