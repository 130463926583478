import React, { useState } from 'react';
import ArrowIcon from '../../assets/arrow-down-right.svg';
import '../../styles/HomeComponent/FAQ.scss';
import { AnimatePresence, motion } from 'framer-motion';

const FAQ = () => {
	const questions = [
		{
			q: `Can I recycle compostable packaging?`,
			a: `Our products are designed to be composted at home or in a 
                industrial composting facility. We ask that you do not dispose 
                our packaging in the recycling bin.
`,
		},
		{
			q: `What makes Starch packaging so unique?`,
			a: `Starch’s compostable products are developed with zero impact
                on the planet with a 100% sustainable supply chain.
`,
		},
		{
			q: `Does Starch provide cling/shrink wrap?`,
			a: `Yes, Starch does offer solutions for cling & shrink wrap.
                   </br>
                 Drop us a line <a href="#contact">here</a>
                 `,
		},
		{
			q: `Where can I locate composting facilities?`,
			a: `Find local facilities near you`,
		},
		{
			q: `Is regular plastic compostable? `,
			a: `Nope`,
		},
		{
			q: `What is left behind when I dispose compostable packaging?`,
			a: `Soil also known as Compost that can later be used to start a garden.`,
		},
	];
	const [showAnswer, setShowAnswer] = useState(null);

	const toggleAnswer = (item) => {
		if (item === showAnswer) {
			setShowAnswer(null);
		} else {
			setShowAnswer(item);
		}
	};
	return (
		<section className={'section-faq'}>
			<div className="container">
				<h2 id={'faqs'}>FAQs</h2>
				<ul>
					{questions.map((item, index) => {
						return (
							<motion.li
								key={index}
								className={showAnswer === index ? 'show' : 'hidden'}
								onClick={() => toggleAnswer(index)}
							>
								<div className="question">
									<ArrowIcon />
									<span>{item.q}</span>
								</div>
								<AnimatePresence exitBeforeEnter>
									{showAnswer === index && (
										<motion.p
											dangerouslySetInnerHTML={{ __html: item.a }}
											initial={{ height: 0 }}
											animate={{
												height: 'auto',
												transition: 0.4,
												opacity: 1,
											}}
											exit={{
												opacity: 0,
												height: 0,
												marginBottom: 0,
												transition: {
													duration: 0.4,
													height: { delay: 0.2 },
												},
											}}
										/>
									)}{' '}
								</AnimatePresence>
							</motion.li>
						);
					})}
				</ul>
			</div>
		</section>
	);
};

export default FAQ;
