import React, {useState} from 'react';
import PropTypes from "prop-types";
import {AnimatePresence, motion} from "framer-motion";

const PackagingReq = ({formData, setFormData, showError}) => {
    const lookingFor = ['Packaging application', 'Reel of film/ laminate', 'Not sure'];
    const segments = ['Fashion', 'Dry food', 'Fresh produce', 'Baked foods', 'Chilled products', 'Coffee/Tea',
        'Frozen food', 'Mailing', 'Cosmetics & Toiletries', 'Toys & Appliance', 'Other']

    const [looking, setLooking] = useState(formData.lookingFor);
    const [segment, setSegment] = useState(formData.segment);
    const [pack, setPack] = useState(formData.pack);
    const [quantity, setQuantity] = useState(formData.quantity);

    const setInput = (key, value) => {
        let newData = formData;
        if (key === 'looking') {
            setLooking(value);
            newData.lookingFor = value;
        } else if (key === 'segment') {
            setSegment(value);
            newData.segment = value;
        } else if (key === 'pack') {
            setPack(value);
            newData.pack = value;
        } else if (key === 'quantity') {
            setQuantity(value);
            newData.quantity = value;
        }
        setFormData(newData)
    }
    return (
        <div className={'flex'}>
            <h3>
                Packaging requirements
            </h3>
            <div className={'side'}>
                <div className="input-group">
                        <span className="label required">
                      I’m looking for
                    </span>
                    <ul className="tag-list">
                        {lookingFor.map((item, index) => (
                            <li role={"presentation"}
                                className={looking === item ? 'active' : ''}
                                onClick={() => setInput('looking', item)}
                                key={index}>{item}</li>
                        ))}
                    </ul>
                    <AnimatePresence exitBeforeEnter>
                        {(showError.show && looking === '') && (
                            <motion.p
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}
                                className="error small">
                                {'Missing field'}</motion.p>)}
                    </AnimatePresence>
                </div>
                <div className="input-group">
                    <div className="label required">
                        Segment
                    </div>
                    <ul className="tag-list">
                        {segments.map((item, index) => (
                            <li
                                role={"presentation"}
                                className={segment === item ? 'active' : ''}
                                onClick={() => setInput('segment', item)}
                                key={index}>{item}</li>
                        ))}
                    </ul>
                    <AnimatePresence exitBeforeEnter>
                        {(showError.show && segment === '') && (
                            <motion.p
                                initial={{opacity: 0}}
                                animate={{opacity: 1}}
                                exit={{opacity: 0}}
                                className="error small">
                                {'Missing field'}</motion.p>)}
                    </AnimatePresence>
                </div>
                <div className="input-group">
                    <span className="label">
                        You wish to pack
                    </span>
                    <input type="text"
                           className={'input'}
                           value={pack}
                           onChange={(e) => setInput('pack', e.target.value)}
                           placeholder={'e.g t-shirts, granola bars, vegetables etc'}/>

                    <div className={'input-placeholder'}>
                        <input type="text"
                               className={'input'}
                               value={quantity}
                               required
                               onChange={(e) => setInput('quantity', e.target.value)}
                        />
                        <div className="placeholder">
                            Order quantity<span>*</span>
                        </div>
                        <AnimatePresence exitBeforeEnter>
                            {(showError.show && quantity === '') && (
                                <motion.p
                                    initial={{opacity: 0}}
                                    animate={{opacity: 1}}
                                    exit={{opacity: 0}}
                                    className="error small">
                                    {'Missing field'}</motion.p>)}
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        </div>
    );
};

PackagingReq.propTypes = {
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired,
    showError: PropTypes.bool.isRequired,
}

export default PackagingReq;
