import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import countryList from 'react-select-country-list';
import ReactCountryFlag from 'react-country-flag';
import { AnimatePresence, motion } from 'framer-motion';

const professions = [
	'Entrepreneur',
	'Brand',
	'Converter',
	'Printer',
	'Distributor',
	'Co-packer',
	'Retail',
	'Manufacturer',
	'Grower',
	'Other',
];

const AboutSelf = ({ formData, setFormData, showError }) => {
	const [occupation, setOccupation] = useState(formData.occupation);
	const [company, setCompany] = useState(formData.companyName);

	const [basedIn, setBasedIn] = useState(formData.basedIn);
	const [showDrop, setShowDrop] = useState(false);
	const [input, setInput] = useState('');
	const [showCountry, setShowCountry] = useState({ label: '' });
	const countries = [
		'Afghanistan',
		'Belarus',
		'Burma',
		'Burundi',
		'Cuba',
		'Dominican Republic',
		'Ethiopia',
		'Hong Kong',
		'Iran, Islamic Republic of',
		'Iraq',
		'Lebanon',
		'Libya',
		'Nicaragua',
		'North Korea',
		'Somalia',
		'Syrian Arab Republic',
		'Ukraine',
		'Venezuela, Bolivarian Republic of',
		'Russia',
		'Yemen',
		'Zimbabwe',
	];
	const options = useMemo(() => countryList().getData(), []);
	let finalCountries = options.filter(
		(item) => !countries.includes(item.label)
	);
	const [filteredCountries, setFilteredCountries] = useState(finalCountries);

	const filterCountries = (country) => {
		let data = finalCountries.filter((item) =>
			item.label.toLowerCase().includes(country)
		);
		setFilteredCountries(data);
	};
	const setInputValue = (key, value) => {
		let newData = formData;
		if (key === 'occupation') {
			setOccupation(value);
			newData.occupation = value;
		} else if (key === 'name') {
			setCompany(value);
			newData.companyName = value;
		} else if (key === 'based') {
			setBasedIn(value);
			newData.basedIn = value;
		}
		setFormData(newData);
	};
	return (
		<>
			<h2>
				Sustainable <br /> made easy.
			</h2>
			<div className={'flex'}>
				<h3>Tell us about yourself</h3>
				<div className={'side'}>
					<div className="input-group">
						<span className="label required">I’m a</span>
						<ul className="tag-list">
							{professions.map((item, index) => (
								<li
									role="presentation"
									className={occupation === item ? 'active' : ''}
									onKeyDown={() => setInputValue('occupation', item)}
									onClick={() => setInputValue('occupation', item)}
									key={index}
								>
									{item}
								</li>
							))}
						</ul>
						<AnimatePresence exitBeforeEnter>
							{showError.show && occupation === '' && (
								<motion.p
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									className="error small"
								>
									{'Missing field'}
								</motion.p>
							)}
						</AnimatePresence>
					</div>
					<div className="input-group">
						<div className={'input-placeholder'}>
							<input
								type="text"
								className={'input'}
								value={company}
								required
								onChange={(ev) => setInputValue('name', ev.target.value)}
							/>
							<div className="placeholder">
								Company Name<span>*</span>
							</div>
							<AnimatePresence exitBeforeEnter>
								{showError.show && company === '' && (
									<motion.p
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
										className="error small"
									>
										{'Missing field'}
									</motion.p>
								)}
							</AnimatePresence>
						</div>
					</div>
					<div className="input-group dropdown-holder">
						<div className={'input-placeholder'}>
							<input
								type="text"
								onFocusCapture={() => setShowDrop(true)}
								onChange={(e) => {
									filterCountries(e.target.value);
									setInput(e.target.value);
									setShowCountry({ label: e.target.value });
								}}
								onBlur={(event) => {
									if (event.relatedTarget?.tagName === 'LI') {
									} else {
										setShowDrop(false);
									}
								}}
								value={showCountry.label}
								className={'input' + ' dropdown'}
								required
							/>

							{showDrop && (
								<div tabIndex="0" className="options">
									<ul tabIndex="0">
										{input !== '' && filteredCountries.length === 0 && (
											<li>No countries found</li>
										)}
										{filteredCountries.map((item) => (
											<li
												key={item.label}
												tabIndex="0"
												onClick={() => {
													setInputValue('based', item.label);
													setShowCountry(item);
													setShowDrop(false);
												}}
											>
												<ReactCountryFlag
													countryCode={item.value}
													svg
													style={{
														width: '20px',
														height: '20px',
														marginRight: '10px',
													}}
													title={item.value}
												/>
												{item.label}
											</li>
										))}
									</ul>
								</div>
							)}
							{basedIn === '' && input === '' && (
								<div className="placeholder">
									Based in<span>*</span>
								</div>
							)}
						</div>
						<AnimatePresence exitBeforeEnter>
							{showError.show && basedIn === '' && (
								<motion.p
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									className="error small"
								>
									{'Missing field'}
								</motion.p>
							)}
						</AnimatePresence>
					</div>
				</div>
			</div>
		</>
	);
};
AboutSelf.propTypes = {
	formData: PropTypes.object.isRequired,
	setFormData: PropTypes.func.isRequired,
	showError: PropTypes.bool.isRequired,
};
export default AboutSelf;
