import React from 'react';
import PropTypes from 'prop-types';

const Confirmation = ({ name }) => {
	return (
		<div className={'flex'}>
			<div className={'confirmation'}>
				<p>{name}, one of our team members will be in touch very soon.</p>
			</div>
		</div>
	);
};
Confirmation.propTypes = {
	name: PropTypes.string.isRequired,
};
export default Confirmation;
