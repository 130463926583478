import React, {useEffect} from 'react';
import './../../styles/HomeComponent/ScrollSection.scss'
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const ScrollSection = () => {
    useEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".sectionScroll",
                scrub: true,
            }
        });

        if (window.innerWidth > 480) {
            tl.fromTo('.box', {width: '50%', height: '20%'}, {
                width: '98%',
                height: '100%',
            })
        } else {
            tl.fromTo('.box', {width: '98%', height: '50%'}, {
                width: '98%',
                height: '100%'
            })
        }

    })

    return (
        <section
            id={'scrollSection'}
            className={'sectionScroll'}>
            <div
                className="box"/>
        </section>
    );
};

export default ScrollSection;
