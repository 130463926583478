import React from 'react';
import CookieIcon from '/src/assets/cookie.svg';
import './CookiePopup.scss';
import { AnimatePresence, motion } from 'framer-motion';

const Popup = ({ showCookie, setShowCookie }) => {
	const variant = {
		show: { opacity: 1, y: 0, x: '-50%' },
		hidden: {
			opacity: 0,
			y: '100px',
			x: '-50%',
			transition: { y: { delay: 0.3 } },
		},
	};
	return (
		<AnimatePresence>
			{showCookie ? (
				<motion.div
					variants={variant}
					initial="hidden"
					animate="show"
					exit="hidden"
					className={'cookiePopup'}
				>
					<div className="icon">
						<CookieIcon />
					</div>
					<p>
						We use cookies to personlize and enhance your experience on our
						site.
					</p>
					<div className="buttons">
						<button onClick={() => setShowCookie(false)}>OK</button>
						<button>Settings</button>
					</div>
				</motion.div>
			) : null}
		</AnimatePresence>
	);
};

export default Popup;
